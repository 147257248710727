
import { computed, defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "user-statistic",
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    statistic: {
      required: true,
      type: Array,
    },
    count: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const color = ref(props.chartColor);
    const baseColor = getCSSVariableValue("--bs-" + color.value);
    const series = computed(() => {
      return props.statistic;
    });
    const chartOptions = {
      chart: {
        height: props.chartHeight,
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },

      dataLabels: {
        enabled: false,
      },

      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val + " user";
          },
        },
      },
    };

    return {
      chartOptions,
      series,
    };
  },
});
