
import { defineComponent, onMounted, ref } from "vue";
import SurveyStatistic from "@/components/widgets/mixed/SurveyCountComponent.vue";
import apiService from "@/core/services/ApiService";
import UserStatistic from "@/components/widgets/mixed/UserStatistic.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "dashboard",
  components: {
    UserStatistic,
    SurveyStatistic,
  },
  setup() {
    let surveyStatistic = ref([
      {
        name: "Surveys",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ]);
    let surveyCount = ref(0);
    const router = useRouter();
    let userStatistic = ref([
      {
        name: "Users",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ]);
    let userCount = ref(0);

    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      getSurveysStatistic();
      getUsersStatistic();
    });

    function getSurveysStatistic() {
      apiService
        .get("statistic/survey")
        .then(({ data }) => {
          surveyStatistic.value = [
            {
              name: "Surveys",
              data: data.months,
            },
          ];
          surveyCount.value = data.count;
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    function getUsersStatistic() {
      apiService
        .get("statistic/user")
        .then(({ data }) => {
          userStatistic.value = [
            {
              name: "Users",
              data: data.months,
            },
          ];
          userCount.value = data.count;
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    return {
      surveyStatistic,
      surveyCount,
      userStatistic,
      userCount,
    };
  },
});
