import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyStatistic = _resolveComponent("SurveyStatistic")!
  const _component_UserStatistic = _resolveComponent("UserStatistic")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.surveyStatistic.length > 0)
        ? (_openBlock(), _createBlock(_component_SurveyStatistic, {
            key: 0,
            statistic: _ctx.surveyStatistic,
            count: _ctx.surveyCount,
            "widget-classes": "card-xxl-stretch-100 mb-5 mb-xl-8",
            "chart-color": "primary",
            "chart-height": "300"
          }, null, 8, ["statistic", "count"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.userStatistic.length > 0)
        ? (_openBlock(), _createBlock(_component_UserStatistic, {
            key: 0,
            statistic: _ctx.userStatistic,
            count: _ctx.userCount,
            "widget-classes": "card-xxl-stretch-100 mb-5 mb-xl-8",
            "chart-color": "primary",
            "chart-height": "300"
          }, null, 8, ["statistic", "count"]))
        : _createCommentVNode("", true)
    ])
  ]))
}